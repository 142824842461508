import makeRequest from '../../request';
import axios, {AxiosError, AxiosResponse} from 'axios';

import error from '../../errors';

import config from '../../config';
import {AUTH_SESSION_NAME, BANK_SESSION_NAME} from '../../../config';

import PaginatedResponse from '../../../types/api/PaginatedResponse';
import Collection, {CollectionValues} from '../../../types/api/Admin/Collections/Collection';
import {ClassBulkActions, IAttributes} from '../../../types/api/Admin/Game/Inventories';
import InventoryClass from '../../../types/api/Admin/InventoryClasses/InventoryClasses';

export async function create(
  name: string,
  inventoryTypeId: string,
  rarity: number,
  archetypeId: string,
  inventoryKindId?: string,
): Promise<AxiosResponse<Collection>> {
  return makeRequest('POST', '/api/banking/admin/collections', {
    name,
    inventoryTypeId,
    rarity,
    archetypeId,
    inventoryKindId,
  });
}

export async function update(
  collectionId: string,
  name: string,
  inventoryTypeId: string,
  rarity: number,
  inventoryKindId?: string,
  archetypeId?: string,
): Promise<AxiosResponse<{ message: string }>> {
  return makeRequest('POST', `/api/banking/admin/collections/${collectionId}`, {
    name,
    inventoryTypeId,
    rarity,
    inventoryKindId,
    archetypeId,
  });
}

export async function updateCollectionImage(
  collectionId: string,
  formData: FormData,
  updateOpenLootImage: boolean = false,
  applyImageToAssets: boolean = false,
  bank: boolean = false,
) {
  try {
    return await axios({
      method: 'post',
      url: `${config.SERVER_PROTO}://${config.SERVER_URL}/api/banking/admin/collections/${collectionId}/image?openloot=${updateOpenLootImage}&applyToAssets=${applyImageToAssets}`,
      data: formData,
      withCredentials: true,
      xsrfHeaderName: 'authorization',
      xsrfCookieName: bank ? BANK_SESSION_NAME : AUTH_SESSION_NAME,
    });
  } catch (err: AxiosError | any) {
    if (err.isAxiosError) {
      error(err);
    }
    throw err;
  }
}

export async function updateCollectionFullScaleImage(
  collectionId: string,
  formData: FormData,
  applyImageToAssets: boolean = false,
  bank: boolean = false,
) {
  try {
    return await axios({
      method: 'post',
      url: `${config.SERVER_PROTO}://${config.SERVER_URL}/api/banking/admin/collections/${collectionId}/full-scale-image?applyToAssets=${applyImageToAssets}`,
      data: formData,
      withCredentials: true,
      xsrfHeaderName: 'authorization',
      xsrfCookieName: bank ? BANK_SESSION_NAME : AUTH_SESSION_NAME,
    });
  } catch (err: AxiosError | any) {
    if (err.isAxiosError) {
      error(err);
    }
    throw err;
  }
}

export async function assignCollectionAsset(
  collectionId: string,
  formData: FormData,
  bank: boolean = false,
) {
  try {
    return await axios({
      method: 'post',
      url: `${config.SERVER_PROTO}://${config.SERVER_URL}/api/banking/admin/collections/${collectionId}/assign-asset`,
      data: formData,
      withCredentials: true,
      xsrfHeaderName: 'authorization',
      xsrfCookieName: bank ? BANK_SESSION_NAME : AUTH_SESSION_NAME,
    });
  } catch (err: AxiosError | any) {
    if (err.isAxiosError) {
      error(err);
    }
    throw err;
  }
}

export async function proceedTreasury(id: string, stakingProgramId: string) {
  return makeRequest('POST', `/api/banking/admin/collections/${id}/treasury`, {
    stakingProgramId,
  });
}

export async function increaseTreasuryPeriod(
  id: string,
  period: number,
  secret: string,
) {
  return makeRequest('POST', `/api/banking/admin/collections/${id}/period`, {
    period,
    secret,
  });
}

export async function getFilterCollections(
  page: number = 1,
  perPage: number = 20,
  {section, inventoryTypes, inventoryKinds}: CollectionValues,
): Promise<AxiosResponse<PaginatedResponse<Collection>>> {
  return makeRequest('POST', '/api/banking/admin/collections/filter', {
    page,
    perPage,
    section,
    inventoryTypes,
    inventoryKinds
  });

}

export async function syncTask({
  collectionId,
  useCollectionImage,
  attributes,
  syncFolder,
  imagesExtension,
}: {
                                   collectionId: string,
                                   useCollectionImage?: boolean,
                                   attributes?: IAttributes[],
                                   syncFolder?: string,
                                   imagesExtension?: string
                               },
) {
  return makeRequest('POST', `/api/banking/admin/collections/${collectionId}/sync-task`, {
    useCollectionImage,
    attributes,
    syncFolder,
    imagesExtension,
  });
}

export async function deleteImage(collectionId: string, fullScale: boolean) {
  return makeRequest('DELETE', `/api/banking/admin/collections/${collectionId}/image`, {fullScale});
}

export async function assignCollectionUserAsset(userId: string, collectionId: string) {
  return makeRequest('POST', `/api/banking/admin/collections/${collectionId}/assign-asset`, {userId});
}

export async function searchCollections(name: string) {
  return makeRequest('GET', `/api/banking/admin/collections/dropdown-results?name=${name}`);
}

export async function getOpenlootRarities(): Promise<AxiosResponse<{ name: string, id: number }[]>> {
  return makeRequest('GET', '/api/banking/admin/openloot/rarities');
}

export async function setCollectionAttributes(collectionId: string, attributes: IAttributes[]) {
  return makeRequest('POST', `/api/banking/admin/collections/${collectionId}/attributes`, {attributes});
}

export async function getClasses(collectionId: string): Promise<AxiosResponse<InventoryClass[]>> {
  return await makeRequest('GET', `/api/banking/admin/game/items-classes/collections/${collectionId}`);
}

export async function addClasses(collectionId: string, classIds: string[]) {
  return await makeRequest('POST', `/api/banking/admin/game/items-classes/collections/${collectionId}`, {classIds});
}

export async function saveBulkActions({id, action, selectedItemId, replaceClassId}: ClassBulkActions) {
  return makeRequest('POST', '/api/banking/admin/game/items-classes/collections-bulk-actions', {
    classId: id, action, collectionIds: selectedItemId, replaceClassId
  });
}