export enum REFRESH_TYPES {
    DASHBOARD = 'DASHBOARD',
    PROFILE_SETTINGS = 'PROFILE_SETTINGS',
    REFERRAL_SYSTEM = 'REFERRAL_SYSTEM',

    APP_HEADER_DROPDOWN = 'APP_HEADER_DROPDOWN',
    CHECK_UNREAD_FUNC = 'CHECK_UNREAD_FUNC',

    ALPHA_KEY_REQUESTS = 'ALPHA_KEY_REQUESTS',
    MANAGE_USERS = 'MANAGE_USERS',
    REFERRAL_REWARDS = 'REFERRAL_REWARDS',
    REFERRAL_EVENTS = 'REFERRAL_EVENTS',
    LEVEL_REWARDS = 'LEVEL_REWARDS',
    AVATARS = 'AVATARS',
    ALPHA_KEY = 'ALPHA_KEY',
    ARTICLES = 'ARTICLES',
    ACHIEVEMENTS = 'ACHIEVEMENTS',
    AUTH_MISSIONS = 'AUTH_MISSIONS',
    ACTIVITY_LOG = 'ACTIVITY_LOG',

    COLLECTIONS = 'COLLECTIONS',
    ECONOMY = 'ECONOMY',
    CONTACTS = 'CONTACTS',
    BADGES = 'BADGES',
    MANAGE_WALLETS = 'MANAGE_WALLETS',
    TRANSACTIONS = 'TRANSACTIONS',

    GAME_VERSIONS = 'GAME_VERSIONS',
    GAME_SESSIONS = 'GAME_SESSIONS',
    INVENTORIES = 'INVENTORIES',
    INVENTORY_TYPES = 'INVENTORY_TYPES',
    TOURNAMENTS = 'TOURNAMENTS',
    PASSES = 'PASSES',
    STATISTICS = 'STATISTICS',
    GAME_MISSIONS = 'GAME_MISSIONS',
    REGIONS = 'REGIONS',
    PLANNED_PLAYTIMES = 'PLANNED_PLAYTIMES',
    FEATURES = 'FEATURES',
    ACTIVITY_EVENTS = 'ACTIVITY_EVENTS',
    TWITCH_EXTENSION = 'TWITCH_EXTENSION',
    TWITTER = 'TWITTER',
    PERKS = 'PERKS',
    IMAGE_FORMATS = 'IMAGE_FORMATS',
    CHARACTER_ATTRIBUTES = 'CHARACTER_ATTRIBUTES',
    MERGE_LINES = 'MERGE_LINES',
    INVENTORY_CLASSES = 'INVENTORY_CLASSES',
    GAMEPLAY_TASKS = 'GAMEPLAY_TASKS',
    MATCH_REWARDS = 'MATCH_REWARDS',
}

const REFRESH_INTERVAL_VALUE = 60 * 1000; // 60 sec

export class RefreshController {
  refreshIntervals: Record<string, ReturnType<typeof setTimeout>> = {};

  set(type: REFRESH_TYPES, callback: () => void, interval = REFRESH_INTERVAL_VALUE): void {
    this.clear(type);
    this.refreshIntervals[type] = setInterval(callback, interval);
  }

  clear(type: REFRESH_TYPES): void {
    if (this.refreshIntervals[type]) {
      clearInterval(this.refreshIntervals[type]);
      delete this.refreshIntervals[type];
    }
  }

  clearAll(): void {
    Object.keys(this.refreshIntervals).forEach((type) => this.clear(type as REFRESH_TYPES));
  }
}

export const refreshController = new RefreshController();