import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import PaginatedResponse from '../../../types/api/PaginatedResponse';
import IDropdownValue from '../../../types/api/Admin/IGeneralTypes';
import ITwitter, {
  AirdropCampaign,
  CompletionMentionReward,
  CompletionReward,
  EditCampaign, EditChestReward,
  IChestReward,
  ISetCompletionMentionReward,
  ISetCompletionReward,
} from '../../../types/api/Admin/Twitter/Twitter';

export async function getTwitterList(page: number = 1, perPage: number = 20): Promise<AxiosResponse<PaginatedResponse<ITwitter>>> {
  return makeRequest('GET', `/api/admin/twitter/posts?page=${page}&perPage=${perPage}`);
}

export async function getCompletionTypes(): Promise<AxiosResponse<IDropdownValue[]>> {
  return makeRequest('GET', '/api/admin/twitter/posts/completion-types');
}

export async function getCompletionRewards(): Promise<AxiosResponse<CompletionReward[]>> {
  return makeRequest('GET', '/api/admin/twitter/posts/completion-rewards');
}

export async function addTwitterPost(twitterId: string) {
  return makeRequest('POST', '/api/admin/twitter/posts', {twitterId});
}

export async function setReward({type, reward}: ISetCompletionReward) {
  return makeRequest('POST', '/api/admin/twitter/posts/completion-rewards', {type, reward});
}

export async function deleteReward(type: number) {
  return makeRequest('DELETE', '/api/admin/twitter/posts/completion-rewards', {type});
}

export async function getAirdropCampaigns(): Promise<AxiosResponse<AirdropCampaign[]>> {
  return makeRequest('GET', '/api/admin/twitter/airdrop-campaigns');
}

export async function deleteCampaign(id: string) {
  return makeRequest('DELETE', `/api/admin/twitter/airdrop-campaigns/${id}`);
}

export async function createCampaign({name, pool, start, end, offSeason, paused}: EditCampaign) {
  return makeRequest('POST', '/api/admin/twitter/airdrop-campaigns', {name, pool, start, end, offSeason, paused});
}

export async function updateCampaign({id, name, pool, start, end, offSeason, paused}: EditCampaign) {
  return makeRequest('POST', `/api/admin/twitter/airdrop-campaigns/${id}`, {name, pool, start, end, offSeason, paused});
}

export async function getMentionCompletionTypes(): Promise<AxiosResponse<IDropdownValue[]>> {
  return makeRequest('GET', '/api/admin/twitter/mention-posts/completion-types');
}

export async function getCompletionMentionRewards(): Promise<AxiosResponse<CompletionMentionReward[]>> {
  return makeRequest('GET', '/api/admin/twitter/mention-posts/completion-caps');
}

export async function deleteMentionReward(type: number) {
  return makeRequest('DELETE', '/api/admin/twitter/mention-posts/completion-caps', {type});
}

export async function setMentionReward({type, cap, reward}: ISetCompletionMentionReward) {
  return makeRequest('POST', '/api/admin/twitter/mention-posts/completion-caps', {type, cap, reward});
}

export async function getChestTypes(): Promise<AxiosResponse<IDropdownValue[]>> {
  return makeRequest('GET', '/api/admin/twitter/chest-rewards/types');
}

export async function deleteChestReward(twitterChestRewardId: string) {
  return makeRequest('DELETE', `/api/admin/twitter/chest-rewards/${twitterChestRewardId}`);
}

export async function getChestRewards(): Promise<AxiosResponse<IChestReward[]>> {
  return makeRequest('GET', '/api/admin/twitter/chest-rewards');
}

export async function createChestReward(state: EditChestReward) {
  return makeRequest('POST', '/api/admin/twitter/chest-rewards', state);
}

export async function updateChestReward(id: string, state: EditChestReward) {
  return makeRequest('POST', `/api/admin/twitter/chest-rewards/${id}`, state);
}