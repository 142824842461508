import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import Region, {EditRegion} from '../../../types/api/Admin/Regions/Regions';
import IDropdownItems from '../../../types/api/Admin/DropdownItems';

export async function getRegions(): Promise<AxiosResponse<Region[]>> {
  return makeRequest('GET', '/api/admin/game/regions');
}

export async function createRegion(state: EditRegion) {
  return await makeRequest('POST', '/api/admin/game/regions', state, undefined, false, false, 180 * 1000);
}

export async function updateRegion(regionId: string, state: EditRegion) {
  return await makeRequest('POST', `/api/admin/game/regions/${regionId}`, state, undefined, false, false, 180 * 1000);
}

export async function deleteRegion(regionId: string) {
  return await makeRequest('DELETE', `/api/admin/game/regions/${regionId}`);
}

export async function getMemoryResources(): Promise<AxiosResponse<IDropdownItems[]>> {
  return makeRequest('GET', '/api/admin/game/regions/memory-resources');
}

export async function getCpuResources(): Promise<AxiosResponse<IDropdownItems[]>> {
  return makeRequest('GET', '/api/admin/game/regions/cpu-resources');
}