import React, {useEffect, useRef, useState, useContext} from 'react';
import {toast} from 'react-toastify';
import {CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody,} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {cilApps} from '@coreui/icons';

import Toast, {ToastType} from '../Toast';
import Modal from '../../Modal/Modal';
import ActionConfirmationModalContent
  from '../../Modal/common/ActionConfirmationModalContent/ActionConfirmationModalContent';
import UserInfo from '../../Modal/Admin/common/UserInfo/UserInfo';

import {useOutsideClick} from '../../../hooks/useOutsideClick/useOutsideClick';
import {useScreenSizeHook} from '../../../hooks/useScreenSizeHook/useScreenSizeHook';
import CONTEXT from '../../../context';

import API from '../../../api';
import {ROLE_NAMES} from '../../../api/constants';

import {
  AppHeaderDropdownMenu,
  AppHeaderDropdownMenuItem,
  AppHeaderDropdownMenuItemButton,
  AppHeaderDropdownMenuItemLink,
  AppHeaderDropdownMenuItemTitle,
  AppHeaderDropdownToggle,
  AppHeaderDropdownWrapper,
  CircularWrapper,
} from './styles';
import './index.css';

const ADMIN_MENU = {
  ADMIN_PANEL: [
    {name: 'Users', link: '/admin/users'},
    {name: 'Referral Rewards', link: '/admin/referral-rewards'},
    {name: 'Referral Events', link: '/admin/referral-events'},
    {name: 'Level Rewards', link: '/admin/level-rewards'},
    {name: 'Avatars', link: '/admin/avatars'},
    {name: 'Alpha keys', link: '/admin/alpha-keys'},
    {name: 'Articles', link: '/admin/articles'},
    {name: 'Achievements', link: '/admin/achievements'},
    {name: 'Missions', link: '/admin/missions'},
    {name: 'Activity Log', link: '/admin/activity-log'},
    {name: 'Twitter', link: '/admin/twitter'},
  ],
  BANK: [
    {name: 'Collections', link: '/admin/collections'},
    {name: 'Economy', link: '/admin/economy'},
    {name: 'Contracts', link: '/admin/contracts'},
    {name: 'Badges', link: '/admin/badges'},
    {name: 'Wallets', link: '/admin/wallets'},
    {name: 'Transactions', link: '/admin/transactions'},
  ],
  GAME: [
    // {name: 'Game sessions', link: '/Admin/Game-sessions'},
    {name: 'Game versions', link: '/admin/game-versions'},
    {name: 'Inventories', link: '/admin/inventories'},
    {name: 'Inventory types', link: '/admin/inventory-types'},
    {name: 'Inventory Classes', link: '/admin/inventory-classes'},
    {name: 'Tournaments', link: '/admin/tournaments'},
    {name: 'Passes', link: '/admin/passes'},
    {name: 'Statistics', link: '/admin/statistics'},
    {name: 'Game Missions', link: '/admin/game-missions'},
    {name: 'Regions', link: '/admin/regions'},
    {name: 'Planned playtimes', link: '/admin/planned-playtimes'},
    {name: 'Features', link: '/admin/features'},
    {name: 'Activity events', link: '/admin/activity-events'},
    {name: 'Twitch Extension', link: '/admin/twitch-extension'},
    {name: 'Balance Table', link: '/admin/balance'},
    {name: 'Perks', link: '/admin/perks'},
    {name: 'Image formats', link: '/admin/image-formats'},
    {name: 'Character Attributes', link: '/admin/character-attributes'},
    {name: 'Merge lines', link: '/admin/merge-lines'},
    {name: 'Gameplay Tasks', link: '/admin/gameplay-tasks'},
    {name: 'Match Rewards', link: '/admin/match-rewards'},
  ],
};

const SUPPORT_MENU = {
  ADMIN_PANEL: [
    {name: 'Alpha Key requests', link: '/admin/alpha-key-requests'},
  ],
};

const AdminDropdown = () => {
  const {width = 320} = useScreenSizeHook();
  const {selectedModalUserId, userInfoModalVisible, setUserInfoModalVisible} = useContext(CONTEXT.GLOBAL.USER);

  const [visible, setVisible] = useState<boolean>(false);
  const [resetStatisticsVisible, setResetStatisticsVisible] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [supportDropdownDisplay, setSupportDropdownDisplay] =
        useState<boolean>(false);

  const getData = async () => {
    setResetStatisticsVisible(false);
    toast(
      <Toast
        customTitle="Reset statistics in progress"
        toastType={ToastType.UndefinedText}
      />,
    );

    const resetStatistics = await API.admin.resetStatistics.resetStatistics();
    if (!resetStatistics) {
      return;
    }

    if (resetStatistics.status === 200) {
      toast.dismiss();
      toast(
        <Toast
          customTitle={'Statistics reset completed'}
          toastType={ToastType.TransactionConfirmed}
        />,
        {
          autoClose: 1000,
        },
      );
    } else {
      toast.dismiss();
      toast(
        <Toast
          customTitle={'Statistics reset failed. Please try again later'}
          toastType={ToastType.TransactionConfirmFailed}
        />,
        {
          autoClose: 1000,
        },
      );
    }
  };

  const checkDropDown = async () => {
    setSupportDropdownDisplay(
      window.roleNames.includes(ROLE_NAMES.ADMIN),
    );
  };

  const onClose = () => {
    setVisible(false);
  };

  useOutsideClick(dropdownRef, onClose, visible);

  useEffect(() => {
    checkDropDown().catch(console.error);
  }, [window.loggedIn, window.roleNames]);

  return (
    <>
      <AppHeaderDropdownWrapper
        ref={dropdownRef}
        style={{position: `${520 > width ? 'static' : 'relative'}`}}
      >
        <CircularWrapper onClick={() => setVisible(!visible)}>
          <CIcon icon={cilApps} size="lg"/>
        </CircularWrapper>
        <AppHeaderDropdownMenu visible={visible}>
          <CAccordion style={{minWidth: '325px'}}>
            <CAccordionItem itemKey={1}>
              <CAccordionHeader>
                <AppHeaderDropdownMenuItemTitle padding="0 20px" className="text-light"
                  style={{border: 0}}>
                                    Admin Panel
                </AppHeaderDropdownMenuItemTitle>
              </CAccordionHeader>
              <CAccordionBody className='custom-scroll-bar'
                style={{maxHeight: '300px', overflowY: 'auto'}}>
                {SUPPORT_MENU.ADMIN_PANEL.map(({name, link}) => (
                  <AppHeaderDropdownMenuItem margin="0 0 12px 0" key={name}>
                    <AppHeaderDropdownMenuItemLink to={link} onClick={onClose}
                      className="text-light">
                      {name}
                    </AppHeaderDropdownMenuItemLink>
                  </AppHeaderDropdownMenuItem>
                ))}
                {supportDropdownDisplay && <>
                  {ADMIN_MENU.ADMIN_PANEL.map(({name, link}) => (
                    <AppHeaderDropdownMenuItem margin="0 0 12px 0" key={name}>
                      <AppHeaderDropdownMenuItemLink to={link} onClick={onClose}
                        className="text-light">
                        {name}
                      </AppHeaderDropdownMenuItemLink>
                    </AppHeaderDropdownMenuItem>
                  ))}
                </>}
              </CAccordionBody>
            </CAccordionItem>
            {supportDropdownDisplay && <CAccordionItem itemKey={2}>
              <CAccordionHeader>
                <AppHeaderDropdownMenuItemTitle padding="0 20px" className="text-light"
                  style={{border: 0}}>
                                    Bank
                </AppHeaderDropdownMenuItemTitle>
              </CAccordionHeader>
              <CAccordionBody className='custom-scroll-bar'
                style={{maxHeight: '300px', overflowY: 'auto'}}>
                {ADMIN_MENU.BANK.map(({name, link}) => (
                  <AppHeaderDropdownMenuItem margin="0 0 12px 0" key={name}>
                    <AppHeaderDropdownMenuItemLink to={link} onClick={onClose}
                      className="text-light">
                      {name}
                    </AppHeaderDropdownMenuItemLink>
                  </AppHeaderDropdownMenuItem>
                ))}
              </CAccordionBody>
            </CAccordionItem>}
            {supportDropdownDisplay && <CAccordionItem itemKey={3}>
              <CAccordionHeader>
                <AppHeaderDropdownMenuItemTitle padding="0 20px" className="text-light"
                  style={{border: 0}}>
                                    Game
                </AppHeaderDropdownMenuItemTitle>
              </CAccordionHeader>
              <CAccordionBody className='custom-scroll-bar'
                style={{maxHeight: '300px', overflowY: 'auto'}}>
                <AppHeaderDropdownMenuItem margin="0 0 12px 0">
                  <AppHeaderDropdownMenuItemButton
                    onClick={() => {
                      setResetStatisticsVisible(true);
                      onClose();
                    }}
                    className="text-light"
                  >
                                        Reset statistics
                  </AppHeaderDropdownMenuItemButton>
                </AppHeaderDropdownMenuItem>
                {ADMIN_MENU.GAME.map(({name, link}) => (
                  <AppHeaderDropdownMenuItem margin="0 0 12px 0" key={name}>
                    <AppHeaderDropdownMenuItemLink to={link} onClick={onClose}
                      className="text-light">
                      {name}
                    </AppHeaderDropdownMenuItemLink>
                  </AppHeaderDropdownMenuItem>
                ))}
              </CAccordionBody>
            </CAccordionItem>}
          </CAccordion>
        </AppHeaderDropdownMenu>
      </AppHeaderDropdownWrapper>

      <Modal
        isOpen={resetStatisticsVisible}
        onClose={() => setResetStatisticsVisible(false)}
      >
        <ActionConfirmationModalContent
          onClose={() => setResetStatisticsVisible(false)}
          actionConfirmation={getData}

          modalTitle={'Reset statistics'}
          modalText={'Are you sure that you want to reset statistics?'}
          buttonTitle={'Reset'}
        />
      </Modal>

      {selectedModalUserId && <Modal
        isOpen={userInfoModalVisible}
        onClose={() => setUserInfoModalVisible(false)}
      >
        <UserInfo
          onClose={() => setUserInfoModalVisible(false)}
          userId={selectedModalUserId}
        />
      </Modal>}
    </>
  );
};

export default AdminDropdown;
