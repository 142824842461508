import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import PaginatedResponse from '../../../types/api/PaginatedResponse';
import IInventories, {ClassBulkActions, InventoryValues} from '../../../types/api/Admin/Game/Inventories';
import IUserInventory from '../../../types/api/Admin/Users/UserInventory';
import IDropdownResults from '../../../types/api/Admin/GameSessions/DropdownResults';
import IInventoryCategories from '../../../types/api/Admin/Game/InventoryCategories';
import InventoryClass from '../../../types/api/Admin/InventoryClasses/InventoryClasses';

export async function getFilterInventories(
  page: number = 1,
  perPage: number = 20,
  {section, rarities, typeIds, kindIds, classIds, name}: InventoryValues,
): Promise<PaginatedResponse<IInventories>> {
  const response = await makeRequest('POST', '/api/admin/game/inventories/filter', {
    page, perPage, section: section?.id, rarities, typeIds, kindIds, classIds, name,
  });
  return response.data;
}

export async function deleteInventory(inventoryId: string) {
  await makeRequest('DELETE', `/api/admin/game/inventories/${inventoryId}`);
}

export async function grantInventory(userId: string, inventoryId: string, amount?: number) {
  return makeRequest('POST', `/api/admin/game/inventories/${inventoryId}/grant`, {userId, amount});
}

export async function grantInventoryForUserInfo(inventoryId: string, userId: string) {
  return makeRequest('POST', `/api/admin/game/inventories/${inventoryId}/grant`, {userId});
}

export async function getUserInventory(userId: string, selectedType: string, page: number = 1, perPage: number = 5): Promise<AxiosResponse<PaginatedResponse<IUserInventory>>> {
  return await makeRequest('GET', `/api/admin/game/user-inventories/${userId}?type=${selectedType}&page=${page}&perPage=${perPage}`);
}

export async function searchInventoriesByName(name: string): Promise<AxiosResponse<IDropdownResults[]>> {
  return await makeRequest('GET', `/api/admin/game/inventories/dropdown-results?name=${name}`);
}

export async function deleteUserInventory(userId: string, inventoryId: string) {
  await makeRequest('DELETE', '/api/admin/game/user-inventories', {userId, inventoryId});
}

export async function getRarityTypes(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return await makeRequest('GET', '/api/admin/game/inventories/rarity-types');
}

export async function getClasses(commonInventoryId: string): Promise<AxiosResponse<InventoryClass[]>> {
  return await makeRequest('GET', `/api/admin/game/items-classes/inventories/${commonInventoryId}`);
}

export async function addClasses(commonInventoryId: string, classIds: string[]) {
  return await makeRequest('POST', `/api/admin/game/items-classes/inventories/${commonInventoryId}`, {classIds});
}

export async function saveBulkActions({id, action, selectedItemId, replaceClassId}: ClassBulkActions) {
  return makeRequest('POST', '/api/admin/game/items-classes/inventories-bulk-actions', {
    classId: id, action, inventoryIds: selectedItemId, replaceClassId,
  });
}