import React from 'react';

// Bossfighters
// const Main = React.lazy(() => import('./views/main/Main'))
// const Welcome = React.lazy(() => import('../pages/Client/Main/Welcome/Welcome'));
const GladiatorDashboard = React.lazy(() => import('../pages/Client/Main/Dashboard/Dashboard'));
// const Staking = React.lazy(() => import('../pages/Client/Unused/Staking/Staking'));
const Inventory = React.lazy(() => import('../pages/Client/Unused/Inventory/Inventory'));
// const Assets = React.lazy(() => import('../pages/Client/Unused/Bank/Assets/Assets'));
// const Capsules = React.lazy(() => import('../pages/Client/Unused/Bank/Capsules/Capsules'));
const ManageUsers = React.lazy(
  () => import('../pages/Admin/Auth/Users/ManageUsers'),
);
const ManageWallets = React.lazy(
  () => import('../pages/Admin/Banking/Wallets/ManageWallets'),
);
const ManageCollections = React.lazy(
  () => import('../pages/Admin/Banking/Collections/ManageCollections'),
);
const ManageTransactions = React.lazy(
  () => import('../pages/Admin/Banking/Transactions/Transactions'),
);
const Login = React.lazy(() => import('../pages/Client/Auth/Login/Login'));
const Register = React.lazy(() => import('../pages/Client/Auth/Register/Register'));
const VerifyEmail = React.lazy(
  () => import('../pages/Client/Auth/VerifyEmail/VerifyEmail'),
);
const ManageAlphaKeyRequests = React.lazy(
  () => import('../pages/Admin/Auth/AlphaKeyRequests/ManageAlphaKeyRequests'),
);
const ForgotPassword = React.lazy(
  () => import('../pages/Client/Auth/ForgotPassword/ForgotPassword'),
);
const ForgotPasswordRequestId = React.lazy(
  () => import('../pages/Client/Auth/ForgotPasswordRequestId/ForgotPasswordRequestId'),
);
const DigitalAssets = React.lazy(
  () => import('../pages/Client/Main/DigitalAssets/DigitalAssets'),
);
const ReferralSystem = React.lazy(
  () => import('../pages/Client/Main/ReferralSystem/ReferralSystem'),
);
const AlphaKeys = React.lazy(
  () => import('../pages/Admin/Auth/AlphaKeys/AlphaKeys'),
);
// const ClaimAlphaKey = React.lazy(
//   () => import('../pages/Client/Auth/ClaimAlphaKey/ClaimAlphaKey'),
// );
const Articles = React.lazy(
  () => import('../pages/Admin/Auth/Articles/Articles'),
);
const ProfileSettings = React.lazy(
  () => import('../pages/Client/Main/ProfileSettings/ProfileSettings'),
);
const UpdateEmailStep1 = React.lazy(
  () => import('../pages/Client/Auth/UpdateEmail/UpdateEmailStep1'),
);
const UpdateEmailStep2 = React.lazy(
  () => import('../pages/Client/Auth/UpdateEmail/UpdateEmailStep2'),
);
const VerificationStatus = React.lazy(
  () => import('../pages/Client/Auth/VerificationStatus/VerificationStatus'),
);
const DiscordLinkingFailed = React.lazy(
  () => import('../pages/Client/Social/Discord/DiscordLinkingFailed'),
);
// const GameSessions = React.lazy(
//   () => import('../pages/Admin/Game/GameSessions/GameSessions'),
// );
const GameVersions = React.lazy(
  () => import('../pages/Admin/Game/GameVersions/GameVersions'),
);
const TwitchLinkingFailed = React.lazy(
  () => import('../pages/Client/Social/Twitch/TwitchLinkingFailed'),
);
const TwitchLinkingSucceeded = React.lazy(
  () => import('../pages/Client/Social/Twitch/TwitchLinkingSucceeded'),
);
const Inventories = React.lazy(
  () => import('../pages/Admin/Game/Inventories/Inventories'),
);
const InventoryTypes = React.lazy(
  () => import('../pages/Admin/Game/InventoryTypes/InventoryTypes'),
);
const Avatars = React.lazy(
  () => import('../pages/Admin/Auth/Avatars/Avatars'),
);
const ReferralRewards = React.lazy(
  () => import('../pages/Admin/Auth/ReferralRewards/ReferralRewards'),
);
const ReferralEvents = React.lazy(
  () => import('../pages/Admin/Auth/ReferralEvents/ReferralEvents'),
);
const Achievements = React.lazy(
  () => import('../pages/Admin/Auth/Achievements/Achievements'),
);
const Missions = React.lazy(
  () => import('../pages/Admin/Auth/Missions/Missions'),
);
const LevelRewards = React.lazy(
  () => import('../pages/Admin/Auth/LevelRewards/LevelRewards'),
);
const PlannedPlaytimes = React.lazy(
  () => import('../pages/Admin/Game/PlannedPlaytimes/PlannedPlaytimes'),
);
const OpenLootFailed = React.lazy(
  () => import('../pages/Client/Social/OpenLoot/OpenLootFailed'),
);
const GameMissions = React.lazy(
  () => import('../pages/Admin/Game/GameMissions/GameMissions'),
);
const OpenLootAccessRefresh = React.lazy(
  () => import('../pages/Client/Social/OpenLoot/OpenLootAccessRefresh'),
);
const Passes = React.lazy(
  () => import('../pages/Admin/Game/Passes/Passes'),
);
const Tournaments = React.lazy(
  () => import('../pages/Admin/Game/Tournaments/Tournaments'),
);
const Statistics = React.lazy(
  () => import('../pages/Admin/Game/Statistics/Statistics'),
);
const RegeneratePassword = React.lazy(
  () => import('../pages/Client/Auth/RegeneratePassword/RegeneratePassword'),
);
const Tournament = React.lazy(() => import('../pages/Client/Main/Tournament/Tournament'));
const ActivityLog = React.lazy(() => import('../pages/Admin/Auth/ActivityLog/ActivityLog'));
const Regions = React.lazy(() => import('../pages/Admin/Game/Regions/Regions'));
const Features = React.lazy(() => import('../pages/Admin/Game/Features/Features'));
const ActivityEvents = React.lazy(() => import('../pages/Admin/Game/ActivityEvents/ActivityEvents'));
const TwitchExtension = React.lazy(() => import('../pages/Admin/Game/TwitchExtension/TwitchExtension'));
const Twitter = React.lazy(() => import('../pages/Admin/Auth/Twitter/Twitter'));
const BalanceTable = React.lazy(() => import('../pages/Admin/Game/BalanceTable/BalanceTable'));
const Perks = React.lazy(() => import('../pages/Admin/Game/Perks/Perks'));
const ImageFormats = React.lazy(() => import('../pages/Admin/Game/ImageFormats/ImageFormats'));
const CharacterAttributes = React.lazy(() => import('../pages/Admin/Game/CharacterAttributes/CharacterAttributes'));
const MergeLines = React.lazy(() => import('../pages/Admin/Game/MergeLines/MergeLines'));
const InventoryClasses = React.lazy(() => import('../pages/Admin/Game/InventoryClasses/InventoryClasses'));
const GameplayTasks = React.lazy(() => import('../pages/Admin/Game/GameplayTasks/GameplayTasks'));
const Economy = React.lazy(() => import('../pages/Admin/Banking/Economy/Economy'));
const Contracts = React.lazy(() => import('../pages/Admin/Banking/Contracts/Contracts'));
const Badges = React.lazy(() => import('../pages/Admin/Banking/Badges/Badges'));
const MatchRewards = React.lazy(() => import('../pages/Admin/Game/MatchRewards/MatchRewards'));

export interface IRoute {
    path: string,
    exact: boolean,
    name: string,
    element: React.LazyExoticComponent<() => JSX.Element>
}

export const publicRoutes: IRoute[] = [
  {path: '/login', exact: true, name: 'Login', element: Login},
  {path: '/register', exact: true, name: 'Register', element: Register},
  {
    path: '/verify-email/:emailVerificationRequestId',
    exact: true,
    name: 'Verify email',
    element: VerifyEmail,
  },
  {
    path: '/forgot-password',
    exact: true,
    name: 'Forgot password',
    element: ForgotPassword,
  },
  {
    path: '/forgot-password/:forgotPasswordRequestId',
    exact: true,
    name: 'Forgot password requestId',
    element: ForgotPasswordRequestId,
  },
  {
    path: '/regenerate-password-request/:regeneratePasswordRequestId',
    exact: true,
    name: 'Regenerate password',
    element: RegeneratePassword,
  },
  {
    path: '/tournament-leaderboards',
    exact: true,
    name: 'Tournament',
    element: Tournament,
  },
  {
    path: '/twitch-linking-failed',
    exact: true,
    name: 'Twitch linking failed',
    element: TwitchLinkingFailed,
  },
  {
    path: '/twitch-linking-succeeded',
    exact: true,
    name: 'Twitch linking succeeded',
    element: TwitchLinkingSucceeded,
  },
];

export const authRoutes: IRoute[] = [];

export const requireAuth = authRoutes.concat([
  {
    path: '/dashboard',
    exact: true,
    name: 'Gladiator\'s dashboard',
    element: GladiatorDashboard,
  },
  {
    path: '/digital-assets',
    exact: true,
    name: 'Digital assets',
    element: DigitalAssets,
  },
  {
    path: '/referral-system',
    exact: true,
    name: 'Referral system',
    element: ReferralSystem,
  },
  {
    path: '/profile-settings',
    exact: true,
    name: 'Profile settings',
    element: ProfileSettings,
  },
  {
    path: '/verification-status',
    exact: true,
    name: 'Verification status',
    element: VerificationStatus,
  },
  {
    path: '/discord-linking-failed',
    exact: true,
    name: 'Discord linking failed',
    element: DiscordLinkingFailed,
  },
  {
    path: '/openloot-linking-failed',
    exact: true,
    name: 'Open Loot failed',
    element: OpenLootFailed,
  },
  {
    path: '/openloot-access-refresh',
    exact: true,
    name: 'Open Loot access refresh',
    element: OpenLootAccessRefresh,
  },
  {
    path: '/inventory',
    exact: true,
    name: 'Inventory',
    element: Inventory,
  },
]);

export const requireAuthUpdateEmailStep1 = authRoutes.concat([
  {
    path: '/update-email/:updateEmailRequestId/step-1',
    exact: true,
    name: 'UpdateEmailStep1',
    element: UpdateEmailStep1,
  },
]);

export const requireAuthUpdateEmailStep2 = authRoutes.concat([
  {
    path: '/update-email/:updateEmailRequestId/step-2',
    exact: true,
    name: 'UpdateEmailStep2',
    element: UpdateEmailStep2,
  },
]);

export const requireSupportOrAdmin = authRoutes.concat([
  {
    path: '/admin/alpha-key-requests',
    exact: true,
    name: 'Manage Alpha Key requests',
    element: ManageAlphaKeyRequests,
  },
]);

export const requireAdmin = authRoutes.concat([
  {
    path: '/admin/users',
    exact: true,
    name: 'Manage users',
    element: ManageUsers,
  },
  {
    path: '/admin/wallets',
    exact: true,
    name: 'Manage wallets',
    element: ManageWallets,
  },
  {
    path: '/admin/collections',
    exact: true,
    name: 'Manage collections',
    element: ManageCollections,
  },
  {
    path: '/admin/transactions',
    exact: true,
    name: 'Manage transactions',
    element: ManageTransactions,
  },
  {
    path: '/admin/alpha-keys',
    exact: true,
    name: 'Alpha keys',
    element: AlphaKeys,
  },
  {
    path: '/admin/articles',
    exact: true,
    name: 'Articles',
    element: Articles,
  },
  // {
  //   path: '/admin/game-sessions',
  //   exact: true,
  //   name: 'GameSessions',
  //   element: GameSessions,
  // },
  {
    path: '/admin/game-versions',
    exact: true,
    name: 'GameVersions',
    element: GameVersions,
  },
  {
    path: '/admin/inventories',
    exact: true,
    name: 'Inventories',
    element: Inventories,
  },
  {
    path: '/admin/inventory-types',
    exact: true,
    name: 'Inventories',
    element: InventoryTypes,
  },
  {
    path: '/admin/avatars',
    exact: true,
    name: 'Avatars',
    element: Avatars,
  },
  {
    path: '/admin/referral-rewards',
    exact: true,
    name: 'Referral Rewards',
    element: ReferralRewards,
  },
  {
    path: '/admin/referral-events',
    exact: true,
    name: 'Referral Events',
    element: ReferralEvents,
  },
  {
    path: '/admin/achievements',
    exact: true,
    name: 'Achievements',
    element: Achievements,
  },
  {
    path: '/admin/missions',
    exact: true,
    name: 'Missions',
    element: Missions,
  },
  {
    path: '/admin/level-rewards',
    exact: true,
    name: 'Level Rewards',
    element: LevelRewards,
  },
  {
    path: '/admin/game-missions',
    exact: true,
    name: 'Game missions',
    element: GameMissions,
  },
  {
    path: '/admin/passes',
    exact: true,
    name: 'Passes',
    element: Passes,
  },
  {
    path: '/admin/tournaments',
    exact: true,
    name: 'Tournaments',
    element: Tournaments,
  },
  {
    path: '/admin/statistics',
    exact: true,
    name: 'Statistics',
    element: Statistics,
  },
  {
    path: '/admin/activity-log',
    exact: true,
    name: 'Activity Log',
    element: ActivityLog,
  },
  {
    path: '/admin/regions',
    exact: true,
    name: 'Regions',
    element: Regions,
  },
  {
    path: '/admin/planned-playtimes',
    exact: true,
    name: 'Planned Playtimes',
    element: PlannedPlaytimes,
  },
  {
    path: '/admin/features',
    exact: true,
    name: 'Features',
    element: Features,
  },
  {
    path: '/admin/activity-events',
    exact: true,
    name: 'Activity Events',
    element: ActivityEvents,
  },
  {
    path: '/admin/twitch-extension',
    exact: true,
    name: 'Twitch Extension',
    element: TwitchExtension,
  },
  {
    path: '/admin/twitter',
    exact: true,
    name: 'Twitter',
    element: Twitter,
  },
  {
    path: '/admin/balance',
    exact: true,
    name: 'Balance Table',
    element: BalanceTable,
  },
  {
    path: '/admin/perks',
    exact: true,
    name: 'Perks',
    element: Perks,
  },
  {
    path: '/admin/image-formats',
    exact: true,
    name: 'Image Formats',
    element: ImageFormats,
  },
  {
    path: '/admin/character-attributes',
    exact: true,
    name: 'Character Attributes',
    element: CharacterAttributes,
  },
  {
    path: '/admin/merge-lines',
    exact: true,
    name: 'Merge Lines',
    element: MergeLines,
  },
  {
    path: '/admin/inventory-classes',
    exact: true,
    name: 'Inventory Classes',
    element: InventoryClasses,
  },
  {
    path: '/admin/gameplay-tasks',
    exact: true,
    name: 'Gameplay Tasks',
    element: GameplayTasks,
  },
  {
    path: '/admin/economy',
    exact: true,
    name: 'Economy',
    element: Economy,
  },
  {
    path: '/admin/contracts',
    exact: true,
    name: 'Contracts',
    element: Contracts,
  },
  {
    path: '/admin/badges',
    exact: true,
    name: 'Badges',
    element: Badges,
  },
  {
    path: '/admin/match-rewards',
    exact: true,
    name: 'MatchRewards',
    element: MatchRewards,
  },
]);

export const routes = [...publicRoutes, ...authRoutes];

export default routes;
