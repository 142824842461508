import axios, {AxiosError, AxiosResponse, Method} from 'axios';
import https from 'https';
import {Readable} from 'stream';

import error from './errors';

import config from './config';
import {AUTH_SESSION_NAME, BANK_SESSION_NAME} from '../config';

const httpsAgent = new https.Agent({
  keepAlive: true,
  keepAliveMsecs: 2000,
});

export default async function makeRequest<R = any, E = any>(
  method: Method,
  path: string,
  data?: any,
  headers?: any,
  bank: boolean = false,
  ignoreAxiosError: boolean = false,
  timeout: number = 95 * 1000
): Promise<AxiosResponse<R> | E> {
  try {
    return await axios({
      url: `${config.SERVER_PROTO}://${config.SERVER_URL}${path}`,
      method,
      data,
      headers,
      httpsAgent,
      insecureHTTPParser: true,
      withCredentials: true,
      xsrfHeaderName: 'authorization',
      xsrfCookieName: bank ? BANK_SESSION_NAME : AUTH_SESSION_NAME,
      timeout,
    });
  } catch (err: AxiosError | any) {
    if (err.isAxiosError) {
      if (ignoreAxiosError) {
        return err.response.data as E;
      }
      error(err);
    }
    throw err;
  }
}

export async function getStreamResponsePost(url: string, data?: any): Promise<Readable> {
  const result = new Readable({
    read() {
    }
  });
  let lastLoaded = 0;
  axios.post(`${config.SERVER_PROTO}://${config.SERVER_URL}${url}`, data, {
    onDownloadProgress: (progressEvent: any) => {
      if (!progressEvent) {
        return;
      }
      if (progressEvent.currentTarget?.responseText) {
        result.push(progressEvent.currentTarget.responseText.slice(lastLoaded));
      }
      lastLoaded = progressEvent.loaded;
      if (progressEvent.currentTarget?.readyState === 4) {
        result.push(null);
      }
    },
    withCredentials: true,
  }).then(() => {
    if (!result.destroyed) {
      result.push(null);
    }
  });
  return result;
}