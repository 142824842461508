import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import {IEditGameVersion, IGameVersion} from '../../../types/api/Admin/GameVersions/GameVersions';
import MessageResponse from '../../../types/api/MessageResponse';

export async function getAllVersions(): Promise<AxiosResponse<IGameVersion[]>> {
  return makeRequest('GET', '/api/admin/game/versions');
}

export async function createGameVersion({
  name, description, balancePresetId, enabled
}: IEditGameVersion): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('POST', '/api/admin/game/versions', {
    name, description, balancePresetId, enabled
  });
}

export async function updateGameVersion({
  id, name, description, balancePresetId, enabled
}: IEditGameVersion): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('POST', `/api/admin/game/versions/${id}`, {
    name, description, balancePresetId, enabled
  });
}

export async function deleteGameVersion(versionId: string) {
  return makeRequest('DELETE', `/api/admin/game/versions/${versionId}`);
}

export async function forceDisable(versionId: string) {
  return makeRequest('POST', `/api/admin/game/versions/${versionId}/force-disable`,);
}

export async function setMainVersion(versionId: string, force?: boolean) {
  return makeRequest('POST', `/api/admin/game/versions/${versionId}/set-main`, {force}, undefined, false, false, 180 * 1000);
}