import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import PaginatedResponse from '../../../types/api/PaginatedResponse';
import MessageResponse from '../../../types/api/MessageResponse';
import IDropdownItems from '../../../types/api/Admin/DropdownItems';
import IBalancePresets, {
  IChangePreset,
  IListPresetParameters,
  IPresetParameter,
  ParameterType, ParameterValue
} from '../../../types/api/Admin/Balance/Balance';

export async function getAllPresets(): Promise<AxiosResponse<IBalancePresets[]>> {
  return makeRequest('GET', '/api/admin/game/balance/presets');
}

export async function getAllTags(): Promise<AxiosResponse<IDropdownItems[]>> {
  return makeRequest('GET', '/api/admin/game/balance/tags');
}

export async function getAllParameterTypes(): Promise<AxiosResponse<ParameterType[]>> {
  return makeRequest('GET', '/api/admin/game/balance/parameter-types');
}

export async function listPresetParameters(
  page: number = 1, perPage: number = 20, {preset, name, types, tags}: IListPresetParameters
): Promise<AxiosResponse<PaginatedResponse<IPresetParameter>>> {
  return makeRequest('POST', `/api/admin/game/balance/presets/${preset?.id}/parameters/filter?page=${page}&perPage=${perPage}`, {
    page: page,
    perPage: perPage,
    name: name,
    types: types,
    tags: tags,
  });
}

export async function createParameter(presetId: string, name: string, type: number, value: ParameterValue, description: string, tags: string[], validation?: any): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('POST', `/api/admin/game/balance/presets/${presetId}/parameters`, {
    name: name,
    type: type,
    value: value,
    description: description,
    tags: tags,
    validation: validation
  });
}

export async function applyChanges(
  presetId: string, changes: IChangePreset[]): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('POST', `/api/admin/game/balance/presets/${presetId}/parameters/update`, changes);
}

export async function deleteParameter(presetId: string, parameterId: string): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('DELETE', `/api/admin/game/balance/presets/${presetId}/parameters/${parameterId}`);
}

export async function renamePreset(presetId: string, name: string): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('POST', `/api/admin/game/balance/presets/${presetId}`, {name: name});
}

export async function deletePreset(presetId: string, replaceWithPresetId?: string): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('DELETE', `/api/admin/game/balance/presets/${presetId}`, {replaceWithPresetId});
}

export async function duplicatePreset(presetId: string, name: string): Promise<AxiosResponse<{ newPresetId: string }>> {
  return makeRequest('POST', `/api/admin/game/balance/presets/${presetId}/duplicate`, {name: name});
}

export async function updatePresetForVersion(versionId: string, balancePresetId: string) {
  return makeRequest('POST', `/api/admin/game/versions/${versionId}/update-preset`, {balancePresetId});
}

export async function updateForAllPresets({
  id, value, description, validation, tags,
}: IChangePreset) {
  return makeRequest('POST', `/api/admin/game/balance/parameters/${id}/update-for-all-presets`, {
    value, description, validation, tags
  });
}

export async function getUpdatedPreset(presetId: string, balanceParameterId: string): Promise<AxiosResponse<IPresetParameter>> {
  return makeRequest('GET', `/api/admin/game/balance/presets/${presetId}/parameters/${balanceParameterId}`);
}