import {AxiosResponse} from 'axios';

import makeRequest from '../../request';
import WalletDetails from '../../../types/api/Admin/Wallets/WalletDetails';

import PaginatedResponse from '../../../types/api/PaginatedResponse';

export async function list(
  page: number = 1,
  perPage: number = 20,
): Promise<AxiosResponse<PaginatedResponse<WalletDetails>>> {
  return makeRequest(
    'GET',
    `/api/banking/admin/wallets?page=${page}&perPage=${perPage}`,
  );
}
